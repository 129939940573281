import { Configuration } from "./configuration";

const BuildConfigurations = (configs: any) => {
  console.log("Configuration------configs", configs);
  Configuration.Environment = configs.env;
  Configuration.NetworkId = configs.networkId;
  Configuration.PartnerId = configs.partnerId;
  Configuration.PlatformUrl = configs.platformUrl;
  Configuration.PlatformUrl_v2 = configs.platformUrl_v2;
  Configuration.BasePath = configs.basePath;
  Configuration.TableManagerUrl = configs.tableManagerUrl;
  Configuration.TournamentManagerUrl = configs.tournamentManagerUrl;
  Configuration.NotificationServiceUrl = configs.notificationServiceUrl;
  Configuration.ClientUrl = `${configs.clientProtocol}://${configs.clientDomain}:${configs.clientPort}/`;
  Configuration.ClientAssetUrl = `${configs.clientProtocol}://${configs.clientDomain}:${configs.clientPort}/${configs.assets}`;
  // Configuration.CloudFrontDomainUrl = configs.cdnBaseUrl;
  Configuration.ClientAssetCommonUrl = `${configs.cdnBaseUrl}${configs.config.commonAssets}`;
  Configuration.ClientAssetHomePageUrl = `${configs.cdnBaseUrl}${configs.config.homePageAssets}`;
  Configuration.ClientAssetMyAccountsPageUrl = `${configs.cdnBaseUrl}${configs.config.myaccountsAssets}`;
  Configuration.ClientAssetPromotionsUrl = `${configs.cdnBaseUrl}${configs.config.promotionsAssets}`;
  Configuration.ClientAssetBankingUrl = `${configs.cdnBaseUrl}${configs.config.bankingAssets}`;
  Configuration.LobbyUrl = `${Configuration.ClientUrl}${configs.lobbyUrl}`;
  Configuration.GameRoomTabType = configs.config.GameRoomTabType;
  Configuration.DefaultCashType = configs.config.defaultCashType;
  Configuration.DefaultMainMenu = configs.config.defaultMainMenu;
  Configuration.DefaultLanguage = configs.config.defaultLanguage;
  Configuration.GoogleClientId = configs.googleClientId;
  Configuration.GoogleApiKey = configs.googleAPIKey;
  Configuration.DevRevId = configs.devRevId;
  Configuration.FacebookClientId = configs.facebookClientId;
  Configuration.DeviceInfoRequired = configs.config.isDeviceInfoRequired;
  Configuration.DeviceCredentials = {
    username: configs.appDeviceUsername ? configs.appDeviceUsername : "",
    password: configs.appDevicePassword ? configs.appDevicePassword : "",
  };
  Configuration.ExpiryBufferMinutes = configs.config.expiryBufferMinutes;
  Configuration.DeviceExpiryTokenInMins =
    configs.config.deviceExpiryTokenInMins;
  Configuration.AccessExpiryTokenInMins =
    configs.config.accessExpiryTokenInMins;
  Configuration.Languages = configs.languages;
  Configuration.MyaccountsMenus = configs.myaccountsMenus;
  Configuration.AboutUsFaqTermsMenus = configs.aboutusFaqMenus;
  Configuration.ForgotPassowrdMenus = configs.forgotMenus;
  Configuration.ChangeUsernameMenus = configs.changeUsernameMenus;
  Configuration.RummyPlayerProtectionAutoPlayMenus =
    configs.rummyplayerprotectionAutoPlayMenus;
  Configuration.ClubDetails = configs.clubDetails;
  Configuration.KycOptions = configs.kyc;
  Configuration.EKycOptions = configs.ekyc;
  Configuration.EkycTextOptions = configs.EkycTextTypes;
  Configuration.KycDocumentTypeOptions = configs.kycDocumentTypes;
  Configuration.HistoryFieldTypes = configs.historyFieldTypes;
  Configuration.PromotionsCategory = configs.promotions.category;
  Configuration.WebsiteMainMenu = configs.websiteMainMenu;
  Configuration.BankingMenus = configs.bankingMenus;
  Configuration.PlayerDefaultAmounts = configs.playerDefaultAmounts;
  Configuration.DepositBonus = configs.depositBonus;
  Configuration.InvalidPromotionTimeInSeconds =
    configs.config.invalidPromotionTimeInSeconds;
  Configuration.DepositPaymentTypes = configs.depositPaymentTypes;
  Configuration.PaymentDepositRedirectionUrl =
    configs.depositPaymentRedirectionUrl;
  Configuration.WithdrawOTPTimeInSeconds =
    configs.config.withdrawOTPTimeInSeconds;
  Configuration.InternalSuccessMessageTimeInSeconds =
    configs.config.internalSuccessMessageTimeInSeconds;
  Configuration.WithdrawPaymentTypes = configs.withdrawPaymentTypes;
  Configuration.BankAccountTypes = configs.bankAccountTypes;
  Configuration.WithdrawPaymentWallets = configs.withdrawPaymentWallets; //configs.config.isUpiEnabled ? : []
  Configuration.SuccessMessageTimeInSeconds =
    configs.config.successMessageTimeInSeconds;
  Configuration.FailureMessageTimeInSeconds =
    configs.config.failureMessageTimeInSeconds;
  Configuration.SideMenus = configs.sideMenuItems;
  Configuration.footerMenus = configs.footerMenus;
  Configuration.footerRummyMenus = configs.footerRummyMenus;
  Configuration.quickMenus = configs.quickMenus;
  Configuration.downloadMenu = configs.downloadMenu;
  Configuration.apiError = configs.apiError;
  Configuration.DepositRetryTimerInSeconds =
    configs.config.depositRetryTimerInSeconds;
  Configuration.WithdrawRetryTimerInSeconds =
    configs.config.withdrawRetryTimerInSeconds;
  Configuration.WithdrawWaitingTimeInSeconds =
    configs.config.withdrawWaitingTimeInSeconds;
  Configuration.DataTableColumns = configs.dataTableColumns;
  Configuration.RefreshTokenIntervalInSeconds =
    configs.config.refreshTokenIntervalInSeconds;
  Configuration.AccountHistoryDepositColumns = configs.accountHistoryColumns;
  Configuration.AccountHistoryLimits = configs.config.accountHistoryLimit;
  Configuration.AccountHistoryReconcileChipTypes =
    configs.accountHistoryReconcileChipType;
  Configuration.VerifyMobileRetryTimerInSeconds =
    configs.config.verifyMobileRetryTimerInSeconds;
  Configuration.PlayerLogsMenus = configs.playerLogsMenus;
  Configuration.GameTypes = configs.gameTypes;
  Configuration.PlayerLogLimits = configs.config.playerLogLimits;
  Configuration.PlayerGameLogLimits = configs.config.playerGameLogLimits;
  Configuration.PlayerLogAvailableLimits = configs.PlayerLogAvailableLimits;
  Configuration.PlayerLogsHistoryColumns = configs.playerLogsHistoryColumns;
  Configuration.ReferNEarnMenus = configs.referNEarnMenus;
  Configuration.ReferNEarnContactsMenus = configs.referNEarnContactsMenus;
  Configuration.ReferNEarnContactsSuccessMenus =
    configs.referNEarnContactsSuccessMenus;
  Configuration.ReferChannelConfigs = configs.referChannelsWindowConfig;
  Configuration.LoggerUrl = configs.loggerServiceUrl;
  Configuration.LogLevels = configs.loggingLevel;
  Configuration.RemoteLogEnabled =
    configs.remoteLoggingEnabled === "true" ? true : false;
  Configuration.LogDisabled = configs.loggingDisabled === "true" ? true : false;
  Configuration.ReduxLogDisabled =
    configs.reduxLoggingDisabled === "true" ? true : false;
  Configuration.middlewareUrl = configs.middlewareUrl;
  Configuration.cdnBaseUrl = configs.cdnBaseUrl;
  Configuration.apk_link = configs.apk_link;
  Configuration.meta_robots = configs.meta_robots;
  Configuration.console_enabled = configs.console_enabled;
  Configuration.support_link = configs.support_link;
  Configuration.hide_tournaments = configs.hide_tournaments;
  Configuration.hide_cashgames = configs.hide_cashgames;
  Configuration.hide_freegames = configs.hide_freegames;
  Configuration.hidden_pages = configs.hidden_pages;
  Configuration.enable_leaderboard_v1 = configs.enable_leaderboard_v1;
  Configuration.allow_mobile_only_login = configs.allow_mobile_only_login;
  Configuration.tds_withdraw_request = configs.tds_withdraw_request;
  Configuration.zen_desk_enabled = configs.zen_desk_enabled;
  Configuration.theme_highlight_color = configs.theme_highlight_color;
  Configuration.disabled_payment_options = configs.disabled_payment_options;
  Configuration.kyc_details_disabled = configs.kyc_details_disabled;
  Configuration.new_my_account_kyc_enabled = configs.new_my_account_kyc_enabled;
  Configuration.version = configs.version;
  Configuration.sid = configs.sid;
  Configuration.custom_header = configs.custom_header;
  //Configuration.IsUpiEnabled = configs.config.isUpiEnabled
  document.cookie = `apk_link=${configs.apk_link}`;
};

const ConfigGenerator = {
  BuildConfigurations: BuildConfigurations,
};

export default ConfigGenerator;
