import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import { CircularProgress, CssBaseline } from "@material-ui/core";
import * as serviceWorker from "./serviceWorker";
import ThemeProvider from "./features/common/themes/ThemProvider";
import ConfigGenerator from "./features/common/utils/configGenerator";
import axios from "axios";

function constructConfigObject(configs: any) {
  ConfigGenerator.BuildConfigurations(configs);
}

axios
  .get("/getconfig/")
  .then((configRes: { data: any }) => {
    constructConfigObject(configRes.data);
    renderApp();
  })
  .catch((err: any) => {
    console.log(err);
  });

function renderApp() {
  console.log(process.env.REACT_APP_CONSOLE_ENABLED, "2225511415");

  if (process.env.REACT_APP_CONSOLE_ENABLED !== "true") {
    console.log = () => {};
    console.error = () => {};
    console.debug = () => {};
  }

  const AppComponent = React.lazy(() => import("./AppComponent"));
  ReactDOM.render(
    <ThemeProvider>
      <CssBaseline />
      <Suspense
        fallback={
          <div className="loaderStyles">
            <CircularProgress color="secondary" />
          </div>
        }
      >
        <AppComponent />
      </Suspense>
    </ThemeProvider>,
    document.getElementById("root")
  );
}

serviceWorker.unregister();
